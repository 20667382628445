import React from "react"

import LayoutComponent from "../components/layout"
import HomepageLogoComponent from "../components/homepageLogo"
import HomepageTitleComponent from "../components/homepageTitle"
import SocialIconsComponent from "../components/socialIcons"

const NotFoundPage = () => {
  return (
    <LayoutComponent pageTitle={"Site not found"}>
      <div
        className={
          "w-full flex-col h-screen inset-0 items-center p-4 flex justify-center bg-gradient-to-br from from-red-500 to-pink-400"
        }
      >
        <HomepageLogoComponent />
        <HomepageTitleComponent title={"Oops, page not found..."} />
        <SocialIconsComponent />
      </div>
    </LayoutComponent>
  )
}

export default NotFoundPage
